.timeline {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr minmax(auto, @reading-width) 1fr;
  margin: 2rem 0;
}

.timeline__nearby {
  position: relative;
  grid-column: ~'2 / 3';
}
.timeline__focus {
  position: relative;
  grid-column: ~'1 / -1';

  margin-top: 2rem;
  margin-bottom: 2rem;
}

.timeline__target {
  position: absolute;
  top: 0.6em;
  right: 0.2em;
}
.timeline__target-icon {
  width: 1.1em;
  height: 1.1em;
  fill: @theme-accent;
}

