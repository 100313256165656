html {
  font-size: 100%; // 16px base fallback
  
  // These days (ie9+) we can have real actual responsive type! NICE
  // ----------------------------------------------------------------------
  // Here's how most explanations outline the method:
  //
  //       1. min font-size       3. min screen size
  //               v                     v
  font-size: calc(1em + 1.1 * ((100vw - 500px) / (1000 - 500) ) );
  //                     ^                             ^
  //  2. (max font-size) minus (min font-size)    4. (max screen size) minus (min screen size)
  // ----------------------------------------------------------------------
  //
  // Where sizes are declared as ems or rems, they will scale in response 
  // to the browser's viewport width according to the above function.
  //
  // 1. The root font-size will stay between min & max font-sizes.
  // 2. The difference of the maximum and minimum intended font-sizes
  //    determines how dramatically everything scales.
  // 3. This is the minimum screen size at which font-size begins to scale.
  // 4. The difference of the maximum and minimum screen sizes determines
  //    the viewport width at which everything can stop scaling up.
  
  line-height: @line-height[body];
  height: 100%;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: @fonts[body];
  font-style: normal;
  font-weight: normal;
  color: @text-colour;
}

section {
  position: relative;
}

input,
textarea,
select,
button {
  font-family: inherit;
}
// yanked from bootstrap 4's reboot.css
a,
area,
button,
[role="button"],
input:not([type=range]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

button {
  font-family: inherit;
  word-break: break-word;
  word-wrap: break-word;
  cursor: pointer;
}
button * {
  // If clicking on anything inside a button, the event should fire on the button itself
  pointer-events: none;
}

b, strong {
  font-weight: bold;
}
i, em, cite {
  font-style: italic;
}
ol, ul {
  padding-left: 0;
  margin-left: 1.2em;
  margin-left: calc(1.2em + 2 * ((100vw - 300px) / 200));
  
  ol, ul {
    margin-bottom: 0;
  }
}
dt {
  font-weight: bold;
}
sup {
  font-size: 0.7em;
  text-transform: lowercase;
}
abbr {
  text-decoration: none;
  border-bottom: none;
}
abbr[title],
span[title] {
  cursor: help;
  text-decoration: underline dotted hsla(0,0,50%,0.5);
}
address {
  font-style: normal;
}
hr {
  margin: 2rem 0;
  height: 0;
  border: solid @line-grey;
  border-width: 1px 0 0;
  
  &.rule--light {
    border-color: @theme-light;
  }
  &.rule--faint {
    border-color: hsla(0,0,100%,0.5);
  }
  &.rule--dark {
    border-color: @grey-dark;
  }
  &.rule--less-margin {
    margin: 1.25rem 0;
  }
}
video {
  display: block;
  max-width: 100%;
}
pre, code {
  font-family: @fonts[mono];
  line-height: @line-height[mono];
}
code {
  padding: 0 0.25em;
  background-color: hsla(0, 0%, 0%, 8%);
  border-radius: 0.2em;
  // color: @text-colour;
}

// base rhythm
// https://csswizardry.com/2012/06/single-direction-margin-declarations/
h1, h2, h3, h4, h5, h6,
ul, ol, dl, dd, p, figure,
pre, table, fieldset, address, blockquote {
  margin-top: 0;
  margin-bottom: 1.25rem;
}


// for funsies
::-moz-selection {
  color: white;
  background-color: @theme-accent;
  text-shadow: none;
}
::selection {
  color: white;
  background-color: @theme-accent;
  text-shadow: none;
}
