@focus-shadow-colour: @theme-accent-light;

.btn {
  position: relative;
  display: inline-block;
  padding: 0.5em 1.125em;
  line-height: @line-height[body];
  text-align: center;
  border-width: 0;
  border-radius: @btn-border-radius;
  box-shadow: none;
  background-color: @grey5;
  background-image: none;
  color: #fff;
  transform: translateY(0);

  &:extend(.link-transitions);

  &:hover {
    background-color: @grey-dark;
    box-shadow: 0 0.05em 0.15em rgba(0,0,0,0.3);
  }
  &:focus-visible {
    outline-color: @theme-light;
    box-shadow:
       3px  3px 0 @theme-accent-light,
      -3px -3px 0 @theme-accent-light,
       3px -3px 0 @theme-accent-light,
      -3px  3px 0 @theme-accent-light;
  }
  &:active {
    transform: translateY(0.05em);
  }
  
  &[target="_blank"] {
    padding: 0.5em 2.125em 0.5em 1.125em;
    
    &::after {
      position: absolute;
      display: block;
      content: '';
      width: 1em;
      height: 1em;
      top: 0.75em;
      right: 0.75em;
      // background-image: url('@{image-path}icons/icon-external-white.svg');
      background-size: 1em;
      background-position: center;
      background-repeat: no-repeat;
      
      transition: filter @t[basic];
    }
  }
}



// -----------------------------------------------------------------------------------------------------------------------------------------
// SIZES
//

.btn--xs {
  font-size: 0.8em;
  padding: 0.1em 0.6em 0.15em;
}
.btn--sm {
  font-size: 0.85em;
}
.btn--lg {
  font-size: 1.2em;
}
.btn--full {
  width: 100%;
}


// -----------------------------------------------------------------------------------------------------------------------------------------
// INLINE DISPLAY
//


.btn-group {
  display: flex;
  gap: 3px;
  flex-wrap: wrap;
  justify-content: center;

  .btn {
    flex: 0 0 auto;
  }
  .btn:not(:last-of-type) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .btn + .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}



// -----------------------------------------------------------------------------------------------------------------------------------------
// OTHER COSMETIC
//

.btn--display {
  font-family: @fonts[heading];
  font-style: normal;
  letter-spacing: 0.015em;
}

// in case there are icons going on and you do not want
.btn--simple,
.btn--simple[target="_blank"] {
  padding: 0.5em 1.125em;
  background-image: none;
  
  &::before,
  &::after {
    content: none;
  }
}

button[disabled],
.btn[disabled] {
  color: @grey-dark;
  background-color: @grey3;
  background-image: repeating-linear-gradient(-45deg,
    transparent,
    transparent 0.9em,
    @grey4 0.9em,
    @grey4 1.8em);
  
  &:hover,
  &:focus,
  &:active {
    background-color: @grey3;
    box-shadow: none;
    transform: translateY(0);
  }
}



// -----------------------------------------------------------------------------------------------------------------------------------------
// COLOURS
//

// colour mixins
.btn-colours(@bg-static; @bg-hover; @bg-active; @text: #fff;) {
  background-color: @bg-static;
  color: @text;
  
  &:hover,
  &:focus {
    background-color: @bg-hover;
  }
  &:active {
    background-color: @bg-active;
  }
}

.btn--white {
  .btn-colours(white; hsl(0,0,85%); hsl(0,0,80%); @grey-darker);
}
.btn--light {
  .btn-colours(@grey2; @grey3; @grey3; @grey-darker);
}
.btn--dark {
  .btn-colours(@grey-dark; @grey-darker; @grey-darker; white);
}
.btn--primary {
  .btn-colours(@theme-accent-light; @theme-accent; darken(@theme-accent, 10%); white);
}
.btn--warning {
  .btn-colours(@theme-accent; darken(@theme-accent, 10%); darken(@theme-accent, 20%); white);
}
