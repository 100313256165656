@media print {
  @page {
    margin: 1.25cm 1cm;
  }

  html {
    font-size: 10pt;
    line-height: 1.4;
  }

  .u-print-break {
    page-break-before: always;
  }

  .site-header,
  .site-footer,
  .article-nav,
  .user-guide__toc,
  .user-guide__version {
    display: none;
  }

  .user-guide__title {
    padding-bottom: 2rem;
  }
  .user-guide__container {
    display: block;

    a {
      text-decoration: none;
      font-weight: bold;
    }
    // a[href^="http"]::after {
    //   content: " (" attr(href) ")";
    //   font-size: 0.8em;
    // }

    figure,
    img {
      display: block;
      page-break-before: auto;
      page-break-after: auto;
      page-break-inside: avoid;
    }
    img {
      max-height: 7in;
      max-width: 5.5in;
    }
    figure {
      margin: 1rem 0;
    }

    h1 {
      margin-bottom: 10pt;
    }
    h2, h3, h4, h5, h6 {
      page-break-after: avoid;
    }
    h2 {
      font-size: @hsize[h2];
    }
    * + h2 {
      margin-top: 1em;
    }
    h3 {
      font-size: @hsize[h3];
    }
    h4 {
      font-size: @hsize[h4];
    }
    h5 {
      font-size: @hsize[h5];
    }

    td,
    tr:first-of-type,
    tr,
    tfoot {
      padding: 0 !important;
      border-width: 0 !important;
    }
  }

  .user-guide__footer-placeholder {
    height: 44pt;
    // border-top: 4px solid black;
  }
  .user-guide__print-footer {
    position: fixed;
    bottom: 0;
    height: 34pt;
    font-size: 7pt;
    padding-top: 4pt;
    border-top: 1px solid @line-grey;
  }

}
