a,
.link {
  // position: relative;
  // z-index: @z[link];
  // 
  // word-break: break-word;
  // word-wrap: break-word;
  // text-decoration: none;
  // color: @theme-accent;
  // background-color: transparent;
  // text-decoration-skip-ink: auto;
  // 
  // box-shadow: inset 0 -2px 0 0 @theme-accent-op25;
  // 
  // &:extend(.link-transitions);
  // 
  // &:hover,
  // &:focus,
  // &:active {
    // box-shadow: inset 0 -2px 0 0 @theme-accent;
  // }

  word-break: break-word;
  word-wrap: break-word;
  // color: inherit;
  color: @theme-accent;
  background-color: transparent;
  cursor: pointer;

  text-decoration: underline;
  text-decoration-color: @theme-accent-op25;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: 2px;

  &:extend(.link-transitions);

  &:hover,
  &:focus,
  &:active {
    text-decoration-color: @theme-accent;
  }


}

a:not(.btn),
.link:not(.btn) {
  outline: 1px solid transparent;
  outline-offset: 2px;
}
a:not(.btn):focus-visible,
.link:not(.btn):focus-visible {
  outline: 2px solid @theme-accent-op25;
}

// pro-tip: <button> will display inline-block no matter what
.link {
  display: inline;
  padding: 0;
  background-color: transparent;
  border-width: 0;
}

// This is because <button> will display inline-block no matter what,
// so nesting a <span> inside it with this class will at least handle
// the link styling properly if it wraps to more than one line
.fake-link {
  text-align: left;
  outline: 1px solid transparent;
  outline-offset: 2px;

  > span {
    .link();
  }

  &:hover,
  &:active {
    > span {
      text-decoration-color: @theme-accent;
    }
  }
  &:focus-visible {
    outline: 2px solid @theme-accent-op25;

    > span {
      text-decoration-color: @theme-accent;
    }
  }
}

.link--search {
  padding-right: 1.1em;
  background-image: url('@{image-path}icons/icon-search-red.svg');
  background-repeat: no-repeat;
  background-size: 0.8em;
  background-position: right center;

  // &::after {
    // content: '';
    // display: inline-block;
    // width: 1em;
    // height: 1em;
    // background-image: url('@{image-path}icons/icon-search-red.svg');
    // background-repeat: no-repeat;
    // background-size: 0.75em;
    // background-position: center;
  // }
}


// [target="_blank"] {
//   padding-right: 0.9em;
//   background-image: url('@{image-path}icons/icon-external-blue-op50.svg');
//   background-repeat: no-repeat;
//   background-size: 0.75em;
//   background-position: right 45%;
// 
//   &.light {
//     background-image: url('@{image-path}icons/icon-external-white-op50.svg');
//   }
// }
// 
// [href$=".pdf"],
// [href^="tel:"],
// [href^="mailto:"] {
//   padding-left: 1.125em;
//   background-repeat: no-repeat;
//   background-size: 0.8em;
// }
// [href$=".pdf"] {
//   background-image: url('@{image-path}icons/icon-download-blue-op50.svg');
//   background-size: 0.9em;
//   background-position: left center;
// 
//   &.light {
//     background-image: url('@{image-path}icons/icon-download-white-op50.svg');
//   }
// }
// [href^="tel:"] {
//   background-image: url('@{image-path}icons/icon-phone-blue-op50.svg');
//   background-position: 0.05em 55%;
//   white-space: nowrap;
// 
//   &.light {
//     background-image: url('@{image-path}icons/icon-phone-white-op50.svg');
//   }
// }
// [href^="mailto:"] {
//   background-image: url('@{image-path}icons/icon-mail-blue-op50.svg');
//   background-position: 0.05em 65%;
// 
//   &.light {
//     background-image: url('@{image-path}icons/icon-mail-white-op50.svg');
//   }
// }
// 
// .link--no-icon {
//   &[target="_blank"],
//   &[href$=".pdf"],
//   &[href^="tel:"],
//   &[href^="mailto:"] {
//     padding-left: 0;
//     padding-right: 0;
//     background-image: none;
//   }
// }
