@keyframes target-highlight {
  0% {
    border: 2px solid transparent;
  }
  5%, 50% {
    border: 2px solid @theme-accent;
  }
  100% {
    border: 2px solid transparent;
  }
}

.user-guide__container {
  @media screen {
    table {
      display: block;
      overflow: visible;
    }
    td {
      font-size: inherit;
    }
    td,
    tr:first-of-type {
      display: block !important;
      padding: 0 !important;
      border-width: 0 !important;
    }
    tbody {
      border-width: 0 0 1px;
    }
    figure {
      display: inline-block;
    }
  }

  h2 {
    font-size: 2.3rem;
  }
  * + h2 {
    margin-top: 2.5em;
  }
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1.75em;
  }
  h3 {
    font-size: 1.6rem;
    color: @theme-accent;
  }
  h4 {
    font-size: 1.4rem;
  }
  h5 {
    font-size: 1rem;
    text-transform: uppercase;
    padding-bottom: 0.3em;
    border-bottom: 1px solid @line-grey;
  }
  h6 {
    font-size: 1rem;
    font-weight: normal;
    padding-bottom: 0.3em;
    border-bottom: 1px dotted @line-grey;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    &:hover > .heading-anchor::before {
      opacity: 1;
    }
  }

  figure {
    margin: 2rem 0;
    padding: 1em;
    border: 2px solid transparent;
    background-color: @grey2;

    &:target {
      animation: 2s target-highlight;
    }
  }
  figcaption {
    display: block;
    margin: 0 0 0.75em;
    padding: 0;
    border-width: 0;
    color: @theme-dark;
    font-style: italic;
  }
  figure img {
    display: block;
    border: 1px solid @grey4;
    box-shadow: 0 0.05em 0.2em hsla(0, 0%, 0%, 0.3);
  }
}

.heading-anchor {
  position: relative;
  float: left;
  text-decoration: none;

  &::before {
    position: absolute;
    content: '#';
    right: 100%;
    padding: 0 0.2rem;
    color: @theme-accent-light;
    font-weight: normal;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
}

.user-guide__title {
  grid-area: title;

  h1 {
    margin-bottom: 0.5rem;
  }

  :last-child {
    margin-bottom: 0;
  }
}

.user-guide__toc {
  margin: 2rem 0;
  border-bottom: 1px solid @line-grey;
}

.user-guide__section-menu {
  margin-left: 0;
  padding-left: calc(1.2em + 2 * ((100vw - 300px) / 200));
  padding-bottom: 2em;
  border-bottom: 1px solid @line-grey;
}



@media screen and (min-width: 40em) {
  .user-guide__container {
    display: grid;
    grid-gap: 2rem;
    grid-template-areas:
      'title title'
      'toc content';
    grid-template-columns: minmax(10rem, 25%) 1fr;
  }

  .user-guide__toc {
    margin: 0;
    padding: 0;
    font-size: 0.9em;
    border-width: 0;
  }
}



@media screen and (min-width: @container-width) {
  .heading-anchor {
    &::before {
      font-size: 1em;
      right: 100%;
      padding: 0 0.2em;
    }
  }
}
