.dl-table {
  dt {
    font-weight: bold;
  }
  dd {
    margin-left: 0;
    padding-left: 0.5em;
    word-wrap: break-word;
    word-break: break-all;
  }
}
.dl-table--dense {
  dd {
    margin-bottom: 0.2em;
  }
}



@media screen and (min-width: 24em) {
  .dl-table {
    display: table;

    dt {
      display: table-cell;
      padding-bottom: 0.5em;
    }
    dd {
      display: table-cell;
      padding-left: 0.75em;
      padding-bottom: 0.5em;
    }
  }
  .dl-table--dense {
    dt,
    dd {
      padding-bottom: 0.25em;
    }
  }
  .dl-table__row {
    display: table-row;
  }
}
