.alert {
  font-size: 0.9em;
  font-style: italic;
  margin-bottom: 1.25rem;
  padding: 1em 1.25em 1.2em;
  border-radius: 0.35em;
  color: inherit;
  background-color: @grey1;
  border: 1px solid @grey3;
  box-shadow: @box-shadow[basic];

  p {
    margin-bottom: 1em;
  }
  & > p:last-child,
  & > ol:last-child,
  & > ul:last-child {
    margin-bottom: 0;
  }
}

.alert--error {
  color: @input-error;
  background-color: lighten(@input-error, 55%);
  border: 1px solid @input-error;
  
  .file-drop__icon path {
    fill: @input-error;
  }

  a,
  .link {
    color: @input-error;
    text-decoration-color: fade(@input-error, 30%);

    &:hover,
    &:focus,
    &:active {
      text-decoration-color: @input-error;
    }
  }
}
.alert--success {
  color: darken(@c3p-green-dark, 5%);
  background-color: lighten(@c3p-green-light, 45%);
  border: 1px solid @c3p-green;
  
  .file-drop__icon path {
    fill: darken(@c3p-green-dark, 5%);
  }

  a,
  .link {
    color: darken(@c3p-green-dark, 5%);
    text-decoration-color: fade(@c3p-green-dark, 30%);

    &:hover,
    &:focus,
    &:active {
      text-decoration-color: @c3p-green-dark;
    }
  }
}
.alert--info {
  color: darken(#2196f3, 30%);
  background-color: lighten(#2196f3, 40%);
  border: 1px solid darken(#2196f3, 20%);
  
  .file-drop__icon path {
    fill: darken(#2196f3, 30%);
  }

  a,
  .link {
    color: darken(#2196f3, 30%);
    text-decoration-color: fade(darken(#2196f3, 30%), 30%);

    &:hover,
    &:focus,
    &:active {
      text-decoration-color: darken(#2196f3, 30%);
    }
  }
}
