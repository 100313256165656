.spider-says__bubble {
  display: inline-block;
  margin: 0;
  padding: 0.25em 1em 0.4em;
  max-width: 15em;
  line-height: 1.2;
  color: white;
  background-color: @theme-accent-light;
  a,
  a:visited,
  a:hover,
  a:active {
    color: inherit;
    text-decoration: underline;
    font-weight: bold;
  }
}
.spider-says__spider {
  display: inline-block;
  width: 10em;
  
  span {
    font-weight: bold;
    color: @theme-accent-light;
  }
}
