.tooltip__container {
  position: relative;
  
}
.tooltip__toggle {
  float: right;
  margin: 0 0.25em;
  font-size: 1em;
  font-weight: bold;
  border-radius: 50%;
  
  .btn-colours(@grey-darker; @theme-dark; @theme-dark; white);
}
.tooltip {
  // position: absolute;
  z-index: @z[tooltip];
  display: block;
  // top: -0.65em;
  // left: 100%;
  // left: calc(100% + 0.5em);
  // width: 100%;
  // top: 100%;
  // top: calc(100% + 0.75em);
  // right: 0.15em;
  margin: 0.8em 0 0.5em 2rem;
  padding: 0.6em 0.8em 0.75em;
  font-size: 0.85em;
  font-style: italic;
  color: @theme-light;
  background-color: @grey-darker;
  border-radius: 0.25em;
  filter: drop-shadow(0 0.1em 0.1em hsla(0, 0%, 0%, 0.5));
  
  &::after {
    position: absolute;
    display: block;
    content: '';
    font-size: 0.85em;
    // top: 0.5em;
    // left: -1.75em;
    top: -1.75em;
    right: 0.4rem;
    width: 1em;
    height: 1em;
    border: 1em solid transparent;
    border-bottom-color: @grey-darker;
  }
}
