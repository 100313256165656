table {
  border-collapse: collapse;
  width: 100%;
  overflow-x: scroll;
}
tbody {
  display: table;
  width: 100%;
  border: solid @grey2;
  border-width: 0 0 2px;
}
th,
td {
  padding: 0.35rem 0.5rem;
  vertical-align: text-top;
  text-align: left;
}
th {
  vertical-align: bottom;
  font-weight: bold;
  background-color: transparent;
}
td {
  font-size: 0.85em;
  
  &::before {
    display: none;
  }
}
tr {
  &:nth-child(odd) {
    background-color: @grey1;
  }
  &:nth-child(even) {
    background-color: white;
  }
  &:first-of-type {
    background-color: transparent;
    border-bottom: 2px solid @grey2;
  }
}
// .responsive-table--double-rows {
//   > tbody > tr {
//     &:nth-child(4n + 0),
//     &:nth-child(4n + 1) {
//       background-color: white;
//     }
//     &:nth-child(4n + 2),
//     &:nth-child(4n + 3) {
//       background-color: @grey1;
//     }
//     &:first-of-type {
//       border-bottom: 2px solid @grey2;
//     }
//   }
// }
caption {
  font-size: @hsize[h3];
  line-height: @line-height[heading];
  font-weight: bold;
  text-align: left;
  padding: 0 0 0.5em;
  color: @theme-accent;
}
.responsive-table__text-right {
  text-align: right;
}



@media screen and @bp[table-sm] {
  .responsive-table,
  tr,
  td {
    display: block;
    width: 100%;
  }
  tr {
    padding: 0.7em 2vw;
  }
  th,
  td {
    padding: 0.25rem 0.5rem;
  }
  th,
  tr:first-of-type {
    display: none;
  }
  tr:nth-of-type(2) {
    border-top: 2px solid @grey2;
  }
  td {
    display: grid;
    grid-template-columns: 5.5em max-content;
    grid-gap: 1em 0.5em;
    
    &::before {
      display: inline;
      padding-right: 0.4em;
      text-align: left !important;
      font-weight: bold;
    }
  }
  td.no-th {
    display: block;
    
    &::before {
      content: none !important;
    }
  }
  
  .responsive-table__text-right {
    text-align: left;
  }
}
