.result-grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1em;
  font-size: 0.74em;
}
.result-grid__item {
  flex: 0 0 auto;
  margin: 0 1em 1em 0;
  padding: 0.5em 0.75em;
  border-radius: 0.2em;
  background-color: @grey2;
}
