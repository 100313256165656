.pagination__control {
  font-size: 0.85em;
  padding-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: center;
}
.pagination__page-display {
  display: inline-block;
  margin: 0 1em;
}
