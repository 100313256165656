.l-main-content {
  display: block;
  flex: 1 0 auto;
  min-height: 15em;
  padding: 2em 0;
}

.l-container {
  position: relative;
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  max-width: @container-width;
}

.l-container__output {
  margin-top: 2em;
}

.l-reading-width {
  max-width: @reading-width;
  
  .l-container & {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.l-flex-row {
  display: flex;
  
  & > * {
    flex: 1 1 auto;
  }
}



@media screen and @bp[container-flex-lg] {
  .l-container--flex {
    display: flex;
  }
  .l-container__controls {
    flex: 3 1 0;
    max-width: 20em;
    padding-right: 1.5em;
  }
  .l-container__output {
    margin-top: 0;
    flex: 8 1 0;
    min-width: 0;
  }
}
