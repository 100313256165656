@import 'css/global/variables';

.bookmark-button {
  height: 30px;
  width: auto;
  padding: 2px;
  cursor: pointer;
  position: relative;
  top: 9px;
}
