// Making classes for heading styles as well is enormously useful for
// keeping good doc hierarchy and easy application of text fanciness

h1,
.h1 {
  font-size: @hsize[h1];
  font-family: @fonts[heading];
  font-weight: bold;
  line-height: @line-height[heading];
  // color: @h1-colour;
  color: inherit;
}
h2,
.h2 {
  font-size: @hsize[h2];
  font-family: @fonts[heading];
  font-weight: normal;
  line-height: @line-height[heading];
  // color: @h2-colour;
  color: inherit;
}
h3,
.h3 {
  font-size: @hsize[h3];
  font-family: @fonts[heading];
  font-weight: bold;
  line-height: @line-height[heading];
  // color: @h3-colour;
  color: inherit;
}
h4,
.h4 {
  font-size: @hsize[h4];
  font-family: @fonts[heading];
}
h5,
.h5 {
  font-size: @hsize[h5];
  font-weight: bold;
}
