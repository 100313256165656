// These aren't really rules for making and using an extend so much as... rules:
//
// 1. Don't nest selectors/pseudo-selectors in this file.
//    Reason: https://css-tricks.com/the-extend-concept/#article-header-id-8
//    Alternative: Nest the extend where it's actually in use, instead of in here, e.g.:
//      .thing {
//        &:extend(.no-box-shadow);
//        &:hover {
//          &:extend(.no-box-shadow);
//        }
//      }
//
// 2. Don't define any media queries in this file.
//    Reason: https://css-tricks.com/the-extend-concept/#article-header-id-9
//    Alternative: Use the declaration as a mixin instead of an extend, e.g.:
//      @media screen and (min-width: 30em) {
//        .thing {
//          .no-box-shadow();
//        }
//      }
// 
// Following these will significantly reduce the chance of unnecessarily messy output.


.link-transitions {
  transition:
    text-decoration @t[basic],
    color @t[basic],
    box-shadow @t[basic],
    background-color @t[basic],
    outline @t[snappy],
    transform @t[snappy];
}

.no-box-shadow {
  box-shadow: none !important;
}

// Overrides for vue/vuetify rules that are getting injected
.v-application{
  font-family: inherit;
}
.v-application a{
  color: @theme-accent;
}
.theme--light.v-application{
  background-color: #ffffff;
}
