.list--simple {
  list-style: none;
  margin-left: 0;
}

.list--inline {
  margin-left: 0;
  padding-left: 0;

  > li {
    display: inline-block;
  }
}

.list--spaced {
  > li {
    margin-bottom: 1.25rem;
  }
  > li > ul,
  > li > ol {
    margin-top: 1.25rem;
  }
}
.list--spaced-xs {
  > li {
    margin-bottom: 0.3em;
  }
  > li > ul,
  > li > ol {
    margin-top: 0.3em;
  }
}
.list--spaced-sm {
  > li {
    margin-bottom: 0.6em;
  }
  > li > ul,
  > li > ol {
    margin-top: 0.6em;
  }
}

.list--counter-reset {
  counter-reset: li;
}

.bold-numbers {
  counter-reset: li;

  > li {
    position: relative;
    list-style: none;

    &::before {
      position: absolute;
      right: 100%;
      margin-right: 0.3em;
      content: counter(li) ".";
      counter-increment: li;
      font-weight: bold;
      text-align: right;
    }
  }
}

.big-numbers { // https://css-tricks.com/numbering-in-style/
  counter-reset: li;
  padding-left: 0;
  margin-top: 1em;

  > li {
    position: relative;
    margin-left: 0.45em;
    list-style: none;

    &::before {
      position: absolute;
      right: 100%;
      margin-right: 0.2em;
      content: counter(li) ".";
      counter-increment: li;
      font-family: @fonts[heading];
      font-weight: bold;
      line-height: @line-height[heading]; // matching heading line-height
      text-align: right;
    }
  }
}
.big-numbers--upper-alpha > li::before {
  content: counter(li, upper-latin) ".";
}
.big-numbers--lower-alpha > li::before {
  content: counter(li, lower-latin) ".";
}
.big-numbers--h1 > li::before {
  font-size: @hsize[h1];
  color: @h1-colour;
}
.big-numbers--h2 > li::before {
  font-size: @hsize[h2];
  color: @h2-colour;
}
.big-numbers--h3 > li::before {
  font-size: @hsize[h3];
  color: @h3-colour;
}
/*[type="a"] {
  counter-reset: li;
  > li {
    position: relative;
    list-style: none;

    &::before {
      position: absolute;
      right: 100%;
      margin-right: 0.2em;
      content: counter(li, lower-alpha) ")";
      counter-increment: li;
      text-align: right;
    }
  }
}*/

.terms-list {
  margin-left: 0;
  counter-reset: li;

  h4 {
    margin-bottom: 0;
  }

  > li {
    position: relative;
    padding-left: 0.2em;
    counter-increment: li;
  }

  > li::marker {
    content: counter(li) ".\a0";
  }

  > li ol {
    counter-reset: section;
  }

  > li ol > li {
    counter-increment: section;
    padding-left: 0.2em;
  }

  > li ol[type="a"] > li::marker {
    content: "(" counter(section, lower-alpha) ")\a0";
  }

  > li ol[type="ua"] > li::marker {
    content: "(" counter(section, upper-alpha) ")\a0";
  }

  > li ol[type="i"] > li::marker {
    content: "(" counter(section, lower-roman) ")\a0";
  }
}
