.clearfix() {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}


.scroll-container-horiz(@shadow-size; @shadow-colour; @bg-colour) {
  @shadow-transparent: transparent;
  @bg-transparent: transparent;

  overflow-x: auto;
  background:
    linear-gradient(to right, @bg-colour, @bg-colour, @bg-transparent (@shadow-size * 2)),
    radial-gradient(farthest-side at 0 50%, @shadow-colour, @shadow-transparent),
    linear-gradient(to left, @bg-colour, @bg-colour, @bg-transparent (@shadow-size * 2)),
    radial-gradient(farthest-side at 100% 50%, @shadow-colour, @shadow-transparent) 100%
    ;
  background-color: @bg-colour;
  background-repeat: no-repeat;
  background-attachment: local, scroll, local, scroll;
  background-size: 100% 100%, @shadow-size 100%, 100% 100%, @shadow-size 100%;
  height: 100%;
}

.scroll-container-vert(@shadow-size; @shadow-colour; @bg-colour) {
  @shadow-transparent: transparent;
  @bg-transparent: transparent;

  overflow-y: auto;
  background:
    linear-gradient(@bg-colour 33%, @bg-colour, @bg-transparent (@shadow-size * 2)),
    linear-gradient(@bg-transparent (@shadow-size * 2), @bg-colour 66%) 0 100%,
    radial-gradient(farthest-side at 50% 0, @shadow-colour, @shadow-transparent),
    radial-gradient(farthest-side at 50% 100%, @shadow-colour, @shadow-transparent) 0 100%;
  background-color: @bg-colour;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 100% 45px, 100% 45px, 100% @shadow-size, 100% @shadow-size;
}
