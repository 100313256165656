.file-drop {
  display: none;
  // margin: 1em 0 1.5em;
  margin: 1em 0;
  // margin: 1em 0 2em;
  // padding: 1em 0.5em;
  // padding: 1em calc(0.5em + 1 * ((100vw - 300px) / 200));
  // min-height: 6em;
  // text-align: center;
  // border-radius: 1em;
  // border: 2px dashed @grey4;
  // background-color: @grey2;
  transition: border-color @t[basic];
  
  .has-js & {
    display: block;
  }
}
.file-drop--error {
  border-color: @form-colour[input-error];
}

.file-drop__text {
  // margin: 0 auto 1em;
  // max-width: 20em;
  font-size: 0.85em;
}
.file-drop__text-inner {
  margin-bottom: 0;
}
.file-drop__browse-btn {
  display: inline-block;
  margin: 0.5em auto 0;
  // width: 100%;
}
.file-drop__files-img {
  position: relative;
  // display: block;
  // margin: 0.5em auto;
  // display: inline-block;
  // vertical-align: middle;
  float: left;
  margin: 0.5em 1em 0 0;
  width: 3.5em;
  height: 3em;
  
  .page-front {
    fill: @grey6;
  }
  .page-back {
    fill: @grey-dark;
  }
  .plus {
    fill: @theme-accent-light;
  }
}

.file-drop__list {
  margin: 0 0 0.25em;
  font-size: 0.9em;
  list-style: none;
  text-align: left;
}
.file-drop__remove-all {
  margin: 1.25em 0 0.5em;
  text-align: right;
}
.file-drop__file {
  position: relative;
  padding: 0.5em 0 1em;
  // padding: 1em 0;
  
  // &:first-of-type {
  //   border-top: 2px solid @grey3;
  // }
  
  
}
.file-drop__details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  
  p {
    margin-bottom: 0;
    padding: 0.1em 0.5em 0 0;
  }
  p:last-of-type {
    padding-right: 0;
  }
  .file-drop--error & p {
    color: @form-colour[input-error];
  }
}
.file-drop__file-name {
  flex: 1 0 100%;
  word-break: break-word;
  word-wrap: break-word;
}
.file-drop__file-size {
  flex: 1 0 auto;
}
.file-drop__file-remove {
  flex: 0 0 auto;
  // width: 4.25em;
  text-align: right;
  
  .btn {
    padding: 0.2rem 0.4rem 0.25rem;
  }
}

.file-drop__icon {
  display: inline-block;
  margin: 0 0.2em -0.2em 0;
  width: 1.2em;
  height: 1.2em;
}
.file-drop__icon--remove {
  margin-right: 0;
  
  path {
    fill: @theme-light;
  }
}
.file-drop__icon--success {
  margin: 0 auto;
  width: 1.8rem;
  height: 1.45rem;
  
  path {
    fill: @form-colour[input-success];
  }
}
.file-drop__icon--success-sm {  
  path {
    fill: @form-colour[input-success];
  }
}
.file-drop__icon--attention {
  path {
    fill: @form-colour[input-error];
  }
}

.file-drop__file-error {
  width: 100%;
  word-break: break-word;
  word-wrap: break-word;
}
.file-drop__file-progress {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 0.6em;
  bottom: 0;
  border: 1px solid @grey4;
  border-radius: 0.3em;
  background-color: @grey3;
  box-shadow: inset 0 0.05em 0.1em hsla(0, 0%, 0%, 0.25);
  opacity: 1;
  transition: opacity 1s;
  
  &.hide {
    opacity: 0;
  }
}
.file-drop__progress {
  display: block;
  width: 0;
  height: 100%;
  
  background-color: @grey5;
  background-image: linear-gradient(-45deg,
    transparent,
    transparent 1em,
    @grey-dark 1em,
    @grey-dark 2em,
    transparent 2em,
    transparent 3em);
  background-size: 3em 100%;
  
  animation: bgslide 1s linear infinite;
}
@keyframes bgslide {
  0% {
    background-position: 3em 0;
  }
  100% {
    background-position: 0 0;
  }
}

.file-drop__results {
  margin-left: 0;
  list-style: none;
}
.file-drop__result {
  margin-top: 1em;
  padding-top: 1em;
  border-top: 2px solid @line-grey;
  
  &:first-of-type {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
}



@media screen and (min-width: 30em) {
  .file-drop__details {
    flex-wrap: nowrap;
  }
  .file-drop__file-name {
    flex: 1 1 auto;
  }
  
  .file-drop__file-size,
  .file-drop__file-remove {
    flex: 0 0 auto;
  }
}
