// Stolen from cybertip.ca's implementation

.article-nav {
  display: flex;
  flex-wrap: wrap;
  margin: 3em 0 0;
  list-style: none;
  border-top: 1px solid @line-grey;
}
.article-nav__item {
  margin: 0;
  flex: 1 0 100%;
  border: solid @line-grey;
  border-width: 0;

  &:not(:empty) {
    padding: 0.35em;
    border-width: 0 0 1px;
  }
  &:last-of-type {
    border-width: 0;
  }
}
.article-nav__item--prev {
  text-align: right;
}
.article-nav__link {
  position: relative;
  display: block;
  padding: 0.5em 1.2em 0.6em;
  text-decoration: none;

  &::after {
    position: absolute;
    display: block;
    content: '';
    width: 1.25em;
    height: 1.25em;
    top: 2.2em;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url('@{image-path}icons/icon-arrow-up.svg');
  }
}
.article-nav__link--prev {
  padding-left: 2.5em;

  &::after {
    content: '';
    left: 0.2em;
    transform: rotate(270deg);
  }
}
.article-nav__link--next {
  padding-right: 2.5em;

  &::after {
    content: '';
    right: 0.2em;
    transform: rotate(90deg);
  }
}
.article-nav__direction-text {
  color: @grey-dark;
  font-style: italic;
}
.article-nav__title {
  text-decoration: underline;
  text-decoration-color: @theme-accent-op25;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: 2px;

  &:extend(.link-transitions);

  .article-nav__link:hover > &,
  .article-nav__link:focus > &,
  .article-nav__link:active > & {
    text-decoration-color: @theme-accent;
  }
}



@supports (mask-image: none) {
  .article-nav__link::after {
    content: none;
    background-image: none;
    mask-image: url('@{image-path}icons/icon-arrow-up.svg');
    mask-repeat: no-repeat;
    mask-position: center right;
    mask-size: contain;
    background-color: currentColor;
  }
  .article-nav__link--prev::after {
    content: '';
    transform: rotate(270deg);
  }
  .article-nav__link--next::after {
    content: '';
    transform: rotate(90deg);
  }
}



@media (min-width: 30em) {
  .article-nav {
    flex-wrap: nowrap;
  }
  .article-nav__item {
    flex: 1 0 50%;
    margin-right: -1px;
    border-width: 0 1px 0 0;

    &:not(:empty) {
      padding: 0.35em;
      border-width: 0 1px 0 0;
    }
    &:last-of-type {
      margin-right: 0;
      border-width: 0;
    }
  }
}

