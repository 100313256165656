.filter legend {
  position: relative;
}

.no-js .filter__select {
  display: none;
}

.filter__select {
  position: absolute;
  top: 0.1em;
  right: 0;
}

.filter__set {
  margin-left: 0;
  margin-bottom: 0;
  padding-left: 0.5em;
  list-style: none;
}

.filter__set li {
  .show-overflow {
    position: relative;
    z-index: 1;
    box-sizing: content-box;
    background-color: white;
    border: 2px solid white;
  }
}


@media screen and @bp[container-flex-lg] {
  .filter__set li {
    max-width: 14em;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
      overflow: visible;
    }
  }

  .filter__set--wrap li {
    white-space: normal;
    overflow: auto;
  }
}
