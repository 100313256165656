// for any gross fixes, last-minute hacks, etc.
// http://csswizardry.com/2013/04/shame-css/

// vuetify override kludges
.v-application {
  &.theme--light {
    color: @theme-dark;
    background-color: @theme-light;
  }
  p {
    margin-bottom: inherit;
  }
}

/* all of Brians css should be considered shameful
 */

div.sample-data-table {

    display: block;
    overflow-x: auto;
    white-space: nowrap;
    min-width: 1000px;
    table {
      width: 100%;
    }



}

form.data-form {
  display: block;


  select {
    border: 2px solid hsl(11, 3%, 80%);
    display: block;
    float: left;
    width: 100px;
  }
}