.u-clearfix {
  // referencing mixin
  .clearfix;
}

// in case declaring a positioning parent is all that's needed
.u-relative {
  position: relative !important;
}


.u-display-block {
  display: block !important;
}



// -----------------------------------------------------------------------------------------------------------------------------------------
// HIDING ELEMENTS
//

// .u-hidden-visually/.u-vh removes an element without making it inaccessible to screen readers
// https://medium.com/@matuzo/writing-css-with-accessibility-in-mind-8514a0007939
.u-hidden-visually,
.u-vh {
  // Remove the item from normal flow
  position: absolute !important;
  // Workaround for falsely pronounced, smushed text
  white-space: nowrap !important;
  // Set it to the smallest possible size
  // (some screen readers ignore elements with zero height and width)
  width: 1px !important;
  height: 1px !important;
  // Hide overflowing content after resizing
  overflow: hidden !important;
  // Reset any property that may change the elements size
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  // Clipping defines what part of an element should be displayed.
  // Deprecated clip property for older browsers
  clip: rect(0 0 0 0);
  // clip-path for newer browsers. inset(50%) defines an
  // inset rectangle that makes the content disappear.
  clip-path: inset(50%);
  // It seems like at the moment nobody is quite sure why margin: -1px is there.
  // On top of that it seems to cause issues
  // (see: https://github.com/h5bp/html5-boilerplate/issues/1985).
  // margin: -1px;
}


// .u-hidden removes an element from view completely, screen readers included,
// though the [hidden] attr does too so this is probably redundant
.u-hidden {
  display: none;
}


.u-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}



// -----------------------------------------------------------------------------------------------------------------------------------------
// COSMETIC UTILITIES
//

.sha1 {
  .sha1-number {
    font-family: @fonts[mono];
    user-select: all;
  }
  tr & {
    word-break: break-all;
    overflow-wrap: break-word;
  }
}

.u-fontsize-sm {      font-size: 0.85em; }
.u-fontsize-normal {  font-size: 1em; }

.u-monospace {        font-family: @fonts[mono]; }

.u-break {            word-break: break-all; }

.u-weight-normal {    font-weight: normal; }
.u-weight-bold {      font-weight: bold; }
.u-style-normal {     font-style: normal; }
.u-style-italic {     font-style: italic; }

.u-uppercase {        text-transform: uppercase; }

.u-text-center,
.u-text-centre {      text-align: center; }
.u-text-left {        text-align: left; }
.u-text-right {       text-align: right; }

.u-text-dark {        color: @theme-dark; }
.u-text-light {       color: @theme-light; }
.u-text-accent {      color: @theme-accent; }

.u-fill-dark {
  path {
    fill: @theme-dark;
  }
}
.u-fill-light {
  path {
    fill: @theme-light;
  }
}
.u-fill-accent {
  path {
    fill: @theme-accent;
  }
}
.u-fill-current {
  path {
    fill: currentColor;
  }
}

.u-underline {
  padding-bottom: 0.2em;
  border-bottom: 2px solid @line-grey;
}

[data-clipboard-text] {
  cursor: copy;
}

.u-copied {
  color: transparent;
  text-shadow: 0 0 8px @theme-accent;
  transition: color 0.1s ease-in-out,
              text-shadow 0.1s ease-in-out;
  position: relative;

  &:after {
    content: "Copied!";
    font-weight: bold;
    text-shadow: none;
    color: @theme-dark;
    position: absolute;
    left: 50%;
    top: 5%;
    margin-left: -30px;
  }
}


// -----------------------------------------------------------------------------------------------------------------------------------------
// UTILITY SPACING
//

@u-xs-sp: 0.25em;
@u-sm-sp: 0.5em;
@u-md-sp: 1.25em;
@u-lg-sp: 2em;
@u-xl-sp: 3em;

// less does not really seem to be good at producing this stuff
// functionally if there are multiple lists to loop through with
// differing lengths, so here they are all written out

// mx/px == applies along x-axis
// my/py == applies along y-axis
// mt == margin-top, pb == padding-bottom, etc.

.u-m-0 {  margin: 0; }
.u-m-xs { margin: @u-xs-sp; }
.u-m-sm { margin: @u-sm-sp; }
.u-m-md { margin: @u-md-sp; }
.u-m-lg { margin: @u-lg-sp; }
.u-m-xl { margin: @u-xl-sp; }

.u-mx-0 {  margin-left: 0;        margin-right: 0; }
.u-mx-xs { margin-left: @u-xs-sp; margin-right: @u-xs-sp; }
.u-mx-sm { margin-left: @u-sm-sp; margin-right: @u-sm-sp; }
.u-mx-md { margin-left: @u-md-sp; margin-right: @u-md-sp; }
.u-mx-lg { margin-left: @u-lg-sp; margin-right: @u-lg-sp; }
.u-mx-xl { margin-left: @u-xl-sp; margin-right: @u-xl-sp; }

.u-my-0 {  margin-top: 0;        margin-bottom: 0; }
.u-my-xs { margin-top: @u-xs-sp; margin-bottom: @u-xs-sp; }
.u-my-sm { margin-top: @u-sm-sp; margin-bottom: @u-sm-sp; }
.u-my-md { margin-top: @u-md-sp; margin-bottom: @u-md-sp; }
.u-my-lg { margin-top: @u-lg-sp; margin-bottom: @u-lg-sp; }
.u-my-xl { margin-top: @u-xl-sp; margin-bottom: @u-xl-sp; }

.u-mt-0 {  margin-top: 0; }
.u-mt-xs { margin-top: @u-xs-sp; }
.u-mt-sm { margin-top: @u-sm-sp; }
.u-mt-md { margin-top: @u-md-sp; }
.u-mt-lg { margin-top: @u-lg-sp; }
.u-mt-xl { margin-top: @u-xl-sp; }

.u-mr-0 {  margin-right: 0; }
.u-mr-xs { margin-right: @u-xs-sp; }
.u-mr-sm { margin-right: @u-sm-sp; }
.u-mr-md { margin-right: @u-md-sp; }
.u-mr-lg { margin-right: @u-lg-sp; }
.u-mr-xl { margin-right: @u-xl-sp; }

.u-mb-0 {  margin-bottom: 0; }
.u-mb-xs { margin-bottom: @u-xs-sp; }
.u-mb-sm { margin-bottom: @u-sm-sp; }
.u-mb-md { margin-bottom: @u-md-sp; }
.u-mb-lg { margin-bottom: @u-lg-sp; }
.u-mb-xl { margin-bottom: @u-xl-sp; }

.u-ml-0 {  margin-left: 0; }
.u-ml-xs { margin-left: @u-xs-sp; }
.u-ml-sm { margin-left: @u-sm-sp; }
.u-ml-md { margin-left: @u-md-sp; }
.u-ml-lg { margin-left: @u-lg-sp; }
.u-ml-xl { margin-left: @u-xl-sp; }


.u-p-0 {  padding: 0; }
.u-p-xs { padding: @u-xs-sp; }
.u-p-sm { padding: @u-sm-sp; }
.u-p-md { padding: @u-md-sp; }
.u-p-lg { padding: @u-lg-sp; }
.u-p-xl { padding: @u-xl-sp; }

.u-px-0 {  padding-left: 0;        padding-right: 0; }
.u-px-xs { padding-left: @u-xs-sp; padding-right: @u-xs-sp; }
.u-px-sm { padding-left: @u-sm-sp; padding-right: @u-sm-sp; }
.u-px-md { padding-left: @u-md-sp; padding-right: @u-md-sp; }
.u-px-lg { padding-left: @u-lg-sp; padding-right: @u-lg-sp; }
.u-px-xl { padding-left: @u-xl-sp; padding-right: @u-xl-sp; }

.u-py-0 {  padding-top: 0;        padding-bottom: 0; }
.u-py-xs { padding-top: @u-xs-sp; padding-bottom: @u-xs-sp; }
.u-py-sm { padding-top: @u-sm-sp; padding-bottom: @u-sm-sp; }
.u-py-md { padding-top: @u-md-sp; padding-bottom: @u-md-sp; }
.u-py-lg { padding-top: @u-lg-sp; padding-bottom: @u-lg-sp; }
.u-py-xl { padding-top: @u-xl-sp; padding-bottom: @u-xl-sp; }

.u-pt-0 {  padding-top: 0; }
.u-pt-xs { padding-top: @u-xs-sp; }
.u-pt-sm { padding-top: @u-sm-sp; }
.u-pt-md { padding-top: @u-md-sp; }
.u-pt-lg { padding-top: @u-lg-sp; }
.u-pt-xl { padding-top: @u-xl-sp; }

.u-pr-0 {  padding-right: 0; }
.u-pr-xs { padding-right: @u-xs-sp; }
.u-pr-sm { padding-right: @u-sm-sp; }
.u-pr-md { padding-right: @u-md-sp; }
.u-pr-lg { padding-right: @u-lg-sp; }
.u-pr-xl { padding-right: @u-xl-sp; }

.u-pb-0 {  padding-bottom: 0; }
.u-pb-xs { padding-bottom: @u-xs-sp; }
.u-pb-sm { padding-bottom: @u-sm-sp; }
.u-pb-md { padding-bottom: @u-md-sp; }
.u-pb-lg { padding-bottom: @u-lg-sp; }
.u-pb-xl { padding-bottom: @u-xl-sp; }

.u-pl-0 {  padding-left: 0; }
.u-pl-xs { padding-left: @u-xs-sp; }
.u-pl-sm { padding-left: @u-sm-sp; }
.u-pl-md { padding-left: @u-md-sp; }
.u-pl-lg { padding-left: @u-lg-sp; }
.u-pl-xl { padding-left: @u-xl-sp; }

// utility text transforms
.u-tt-cap {
  text-transform: capitalize;
}
.u-tt-up {
  text-transform: uppercase;
}
.u-tt-low {
  text-transform: lowercase;
}

// utility widths
.u-full-width {
  width: 100%;
}
.u-half-width {
  width: 50%;
}
