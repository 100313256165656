@message-padding-top: 1rem;
@message-padding-bottom: 1.2rem;
@message-padding-side: 1rem;

@zigbg: @grey1;
@zagbg: @grey3;
@zigsize: 24px;
@zigbgsize: 20px 40px;

.control-message-content() {
  // A mixin of styles for overriding whatever comes in
  h1, h2, h3, h4, h5, h6 {
    font-size: 1.1em;
  }
  img {
    width: 1em !important;
    height: 1em !important;
    opacity: 0.3;
    border: 4px solid black;
  }
  blockquote {
    padding-left: 1rem;
    font-size: 0.75rem;
    color: @grey6;
    border-left: 3px solid @line-grey;
  }
  .author,
  .signature,
  .neutral_thx,
  .good_thx,
  .thx_list_normal,
  ul.comment-meta,
  .post-buttons {
    display: none !important;
  }
}



.message {
  // Don't use overflow: hidden here to tuck child nodes' corners into the border-radius
  // because that will mess up .message__body-tldr's sticky positioning
  margin-bottom: 2rem;
  border: solid @grey3;
  border-width: 2px;
  border-radius: 0.35rem;
  background-color: @grey3;

  > * {
    padding-left: @message-padding-side;
    padding-right: @message-padding-side;
  }

}
.message--chat-simple {
  margin-bottom: 0;
  border-color: transparent;
  background-color: transparent;
}



.message__metadata {
  position: relative;
  display: grid;
  grid-template-columns: 1fr max-content;
}

.message__metadata-info {
  grid-column: 1;
}

.message__metadata-onion {
  grid-column: 2;
  align-self: start;

  padding-top: 0.25em;
  padding-left: 0.5em;

  img {
    width: 3.2em;
    height: auto;
  }
}

.message__details-wrapper {
  font-size: 0.85em;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  background-color: @grey3;
}

.message__details {
  margin-bottom: 0;

  summary::marker {
    color: @theme-accent;
  }

  &[open] > summary {
    margin-bottom: 0.5rem;
    padding-bottom: 0.25rem;
    border-bottom: 1px solid @grey2;
  }

}
.message__details--chat {
  padding-bottom: 0.25rem;
  border-bottom: 1px solid @grey2;
}



.message__header {
  font-size: 0.85em;
  padding-top: 0.4rem;
  padding-bottom: 0.75em;
  list-style: none;

  li {
    margin-bottom: 0;
  }
}
.message__header--forum {
  background-color: @grey1;
  border-bottom: 1px solid @grey3;
}

.message__author {
  font-weight: bold;
}

.message__body {
  position: relative;
  padding-top: @message-padding-top;
  padding-bottom: @message-padding-bottom;
  background-color: @grey1;

  & > :last-child {
    margin-bottom: 0;
  }

  .control-message-content();
}
.message__body--forum {
  padding-right: 3.75rem;
}
.message__body--forum {
  &:last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}
.message__body--chat {
  margin: 0.25rem @message-padding-side;
  background-color: @grey1;
  border-radius: 1rem;
  padding-right: 3.75rem;

  .message--chat-simple & {
    margin-left: 0;
    margin-right: 0;
    background-color: @grey2;

    &::before {
      left: 3.5em;
      border-bottom-color: @grey2;
    }
  }

  &:last-child {
    margin-bottom: 1rem;
  }

  &::before {
    position: absolute;
    display: block;
    content: '';
    width: 1.2em;
    height: 1.2em;
    top: -1.2em;
    left: 2em;
    border: 0.6em solid transparent;
    border-bottom-color: @grey1;
  }
}



.message__body-inner {
  padding-bottom: 1.5rem;

  &[data-long-post-hidden="true"] {
    max-height: 12em;
    overflow: hidden;
    padding-bottom: 2.5em;
  }
}
.message__body-inner--height-limit {
  padding: @message-padding-top @message-padding-side;
  max-height: 12vh;
  border-radius: 1rem;

  .scroll-container-vert(@shadow-size: 0.5rem; @shadow-colour: @grey4; @bg-colour: @grey1);

  .message--chat-simple & {
    .scroll-container-vert(@shadow-size: 0.5rem; @shadow-colour: @grey4; @bg-colour: @grey2);
  }
}



.message__keywords {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid @grey3;

  &[data-long-post-hidden="true"] {
    padding-bottom: 2.5rem;
    margin-bottom: 0.5rem;
    border-width: 0;
  }

  em {
    padding: 0 0.2em;
    background-color: orange;
  }
}
.message__keyword-result {
  margin-bottom: 0.5rem;

  * {
    margin: 0;
  }
}



.message__body-tldr {
  position: sticky;
  z-index: 1;
  margin: 1rem -(@message-padding-side) 0;
  bottom: 0.5rem;
  text-align: center;

  .message__body--chat & {
    bottom: 1rem;
  }

  &[data-long-post-hidden="true"] {
    position: absolute;
    margin: 0;
    left: 0;
    width: 100%;
    background-image: linear-gradient(to top, @grey3, @grey3 40%, transparent);
  }

}

.message__btn-tldr {
  display: block;
  width: 100%;
  padding: 0 1.125em;
  line-height: @line-height[body];
  text-align: center;
  border-width: 0;
  background-color: transparent;

  [data-long-post-hidden="true"] & {
    padding-bottom: 0.5em;
    background-image:
      linear-gradient(-45deg, @zigbg (@zigsize - 2), red (@zigsize - 2), blue (@zigsize - 2), transparent 0),
      linear-gradient(45deg, @zigbg (@zigsize - 2), transparent 0),
      linear-gradient(-45deg, @zagbg @zigsize, red @zigsize, blue @zigsize, transparent 0),
      linear-gradient(45deg, @zagbg @zigsize, transparent 0)
      ;
    background-repeat: repeat-x;
    background-size: @zigbgsize;
    background-position: center bottom;
  }

  .btn {
    .message__body-tldr:hover &,
    .message__body-tldr:focus &,
    .message__body-tldr:active & {
      box-shadow: 0 0.05em 0.15em rgba(0,0,0,0.2);
    }
    .message__body-tldr:hover & {
      background-color: @grey-dark;
    }
    .message__body-tldr:focus & {
      outline-color: @theme-accent;
      box-shadow:
         3px  3px 0 @focus-shadow-colour,
        -3px -3px 0 @focus-shadow-colour,
         3px -3px 0 @focus-shadow-colour,
        -3px  3px 0 @focus-shadow-colour;
    }
    .message__body-tldr:active & {
      transform: translateY(0.05em);
    }
  }
}

// This is to make the toggling of ciphertext/plaintext display work.  Pls fix

.c3p-encrypted {
  border: 1px solid #000;
  padding: 20px;
  cursor: pointer;
  margin-top: 10px;
}

.c3p-encrypted:hover {
  background-color: @grey2;
}

.c3p-ciphertext {
  display: none;
  word-wrap: break-word;
}

.c3p-plaintext {
  display: block;
  &:before {
    content: 'Decrypted';
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.c3p-encrypted.show-ciphertext {
  .c3p-ciphertext {
    display: block;
    &:before {
      content: 'Encrypted';
      display: block;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  .c3p-plaintext {
    display: none;
  }
}
