.image-result {
  overflow-x: scroll;
  
  .dl-table {
    margin-bottom: 0.75em;
  }
  .dl-table dd {
    margin-bottom: 0.75em;
  }
  
  table {
    overflow-x: visible;
  }
}
