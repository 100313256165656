.dl--inline {
  dt {
    float: left;
    margin-right: 0.35em;
  }
  dd {
    margin-left: 0;
  }
}
.dl--dense {
  dd {
    margin-bottom: 0;
  }
}
