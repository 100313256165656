.site-footer {
  font-size: 0.8em;
  display: block;
  flex: 0 0 auto;
  padding: 0.5em 0 1.5em;
  // margin-top: 3em;
  
  color: @theme-light;
  background-color: @grey-darker;
  border-top: 0.5em solid @theme-dark;

  a {
    color: inherit;
    box-shadow: none;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: hsla(0, 0%, 100%, 0.3);
  }
}

.site-footer__nav {
  display: flex;
  flex-wrap: wrap;
  margin: 1em 0 1.5em;
  width: 100%;
  list-style: none;
  justify-content: center;
}
.site-footer__nav-item {
  flex: 0 1 auto;
  padding: 0.2em 0.75em;
}
.site-footer__program-of {
  margin: 2em 0;
  text-align: center;
  
  a,
  a:hover,
  a:focus,
  a:active {
    box-shadow: none;
  }
}
.site-footer__c3p-logo {
  display: inline-block;
  vertical-align: middle;
  width: 90%;
  max-width: 18em;
  margin: 0.5em;
}



@media screen and (min-width: 32em) {
  .site-footer__program-of {
    margin: 1em 0 2em;
  }
}
