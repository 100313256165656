.font-mixin(@font-family; @font-weight; @font-style; @font-stretch; @font-src) {
  font-family: @font-family;
  font-weight: @font-weight;
  font-style: @font-style;
  font-stretch: @font-stretch;
  src: url('@{font-path}@{font-src}-subset.woff2') format('woff2'),
       url('@{font-path}@{font-src}-subset.zopfli.woff') format('woff'),
       url('@{font-path}@{font-src}-subset.ttf') format('truetype');
  font-display: swap;
}

@font-face { .font-mixin('IBMPlexSans'; normal; normal; normal; 'IBMPlexSans-Regular'); }
@font-face { .font-mixin('IBMPlexSans'; normal; italic; normal; 'IBMPlexSans-Italic'); }
@font-face { .font-mixin('IBMPlexSans'; bold;   normal; normal; 'IBMPlexSans-Bold'); }
@font-face { .font-mixin('IBMPlexSans'; bold;   italic; normal; 'IBMPlexSans-BoldItalic'); }

@font-face { .font-mixin('IBMPlexMono'; normal; normal; normal; 'IBMPlexMono-Regular'); }
@font-face { .font-mixin('IBMPlexMono'; normal; italic; normal; 'IBMPlexMono-Italic'); }
@font-face { .font-mixin('IBMPlexMono'; bold;   normal; normal; 'IBMPlexMono-Bold'); }
@font-face { .font-mixin('IBMPlexMono'; bold;   italic; normal; 'IBMPlexMono-BoldItalic'); }
