@input-border-colour: @grey3;

@checked-colour: @theme-dark;

@focus-border-colour: @grey-dark;
@focus-shadow-width: 3px;
@focus-shadow-colour: @grey3;

fieldset {
  display: block;
  margin: 0 0 1.25rem;
  padding: 0;
  border-width: 0;
}
legend:not(.label) {
  width: 100%;
  margin-bottom: 0.5em;
  padding-bottom: 0.2em;
  font-weight: bold;
  border-bottom: 1px solid @line-grey;
}
.helper-text {
  margin: 0.25em 0 0.5em;
}
.helper-text--sm {
  font-size: 0.85em;
  margin: 0.5em 0 0.75em 0.1em;
  padding-left: 0.75em;
  color: @grey6;
  border-left: 4px solid @grey3;

}

.form--inline {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 1em;

  input[type="radio"] + label {
    padding-left: 1.5em;
  }

}




// -----------------------------------------------------------------------------------------------------------------------------------------
// INPUTS

input,
textarea,
select {
  font-family: inherit;
  min-width: 10em;
}
input,
textarea,
select {
  padding: 0.4em 0.6em;
  line-height: inherit;
  width: 100%;
  border: 2px solid @input-border-colour;
  border-radius: 0;
  box-shadow: 0 0 0 0 @focus-shadow-colour;
  transition: border @t[basic], box-shadow @t[basic];

  &:focus {
    border-color: @focus-border-colour;
    box-shadow: 0 0 0 @focus-shadow-width @focus-shadow-colour;
  }
}
input[type="number"] {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
  }
}
input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  width: auto;
  opacity: 0;
  // try to avoid shoving annoying horizontal scroll bars into existence
  top: 0;
  left: 0;
}
input[type="search"] {
  -webkit-appearance: none;
  border-radius: 0;
}

// .select-wrapper {
  // line-height: 1; // because of blink/webkit's dumb extra spacing
  // width: 100%;
  // border: 2px solid @input-border-colour;
  //
  // // bg image:
  // // .icon-arrow-down--grey-dark();
  // background-repeat: no-repeat;
  // background-size: 1em;
  // background-position: right 0.4em center;
  // box-shadow: 0 0 0 0 @focus-shadow-colour;
  //
  // transition: border @t[basic], box-shadow @t[basic];
  //
  // &.short {
    // max-width: 20em;
  // }
  //
  // &:focus,
  // &:focus-within {
    // border-color: @focus-border-colour;
    // box-shadow: 0 0 0 @focus-shadow-width @focus-shadow-colour;
  // }
// }
// select {
  // line-height: @line-height[body];
  // padding: 0.4em 1.75em 0.4em 0.5em;
  // width: 100%;
  // overflow: hidden;
  // white-space: nowrap;
  // border: none;
  // box-shadow: none;
  // background-color: transparent;
  // background-image: none;
  // -webkit-appearance: none;
     // -moz-appearance: none;
          // appearance: none;
  // text-overflow: ellipsis;
  //
  // &::-ms-expand {
    // display: none;
  // }
  //
  // > option {
    // position: relative;
    // margin: 0;
    // padding: 0;
    // -webkit-appearance: menulist-button;
    //
    // &::after {
      // position: absolute;
      // display: block;
      // content: attr(value string);
    // }
  // }
// }
select {
  padding-left: 0.35em;
  padding-right: 1.75em;
  white-space: nowrap;
  appearance: none;
  text-overflow: ellipsis;

  background-image: url('@{image-path}icons/icon-arrow-down.svg');
  background-repeat: no-repeat;
  background-size: 1.2em;
  background-position: right 0.4em center;
  box-shadow: 0 0 0 0 @form-colour[outline-static];

  &::-ms-expand {
    display: none;
  }

  > option {
    font-family: inherit;
    line-height: inherit;

    margin: 0;
    padding: 0;
    -webkit-appearance: menulist-button;

    &[disabled]:not(:empty) {
      display: none;
    }
  }
}

.search-with-btn input{
  width: 94%;
}



// -----------------------------------------------------------------------------------------------------------------------------------------
// LABELS AND RELATED TRICKERY

label,
.label {
  position: relative;
  display: inline-block;
  margin-bottom: 0.25em;
  width: 100%;
  line-height: inherit;

  border-top: 2px solid transparent; // for alignment

  // custom checkbox and radio inputs
  input[type="checkbox"] + &,
  input[type="radio"] + & {
    padding-left: 2em;
  }
  input[type="checkbox"] + &::before,
  input[type="radio"] + &::before {
    position: absolute;
    display: block;
    content: '';
    top: 0.2em;
    left: 0;
    width: 1.2em;
    height: 1.2em;
    background-color: white;
    border: 2px solid darken(@input-border-colour, 10%);
    box-shadow: 0 0 0 0 @focus-shadow-colour;
    transition: border-color @t[basic], box-shadow @t[basic];
  }
  input[type="checkbox"]:focus + &::before,
  input[type="radio"]:focus + &::before {
    border-color: @focus-border-colour;
    box-shadow: 0 0 0 @focus-shadow-width @focus-shadow-colour;
  }
  input[type="radio"] + &::before {
    border-radius: 50%;
  }

  input[type="checkbox"] + &::after,
  input[type="radio"] + &::after {
    position: absolute;
    display: block;
    content: '';
    opacity: 0;
    transition: opacity @t[snappy];
  }
  input[type="checkbox"]:checked + &::after {
    opacity: 1;
    top: -0.15em;
    left: 0.5em;
    width: 0.7em;
    height: 1.2em;
    border: solid @checked-colour;
    border-width: 0 0.3em 0.3em 0;
    transform: rotate(45deg);
  }
  input[type="radio"]:checked + &::after {
    opacity: 1;
    top: 0.2em;
    left: 0;
    width: 1.2em;
    height: 1.2em;
    border-radius: 50%;
    border: 0.3em solid transparent;
    background-clip: content-box;
    background-color: @checked-colour;
  }

  small {
    font-style: italic;
    color: @grey6;
  }
}
.label-radio {
  display: block;
  width: auto;
  padding-top: 0;
  text-align: left;

  input {
    width: auto;
    margin-right: 0.5em;
  }
}
.label-radio--inline {
  display: inline-block;
}



// -----------------------------------------------------------------------------------------------------------------------------------------
// GROUP WRAPPERS

.form-group {
  margin-bottom: 1.25rem;
}
.form-group--inline-block {
  display: inline-block;
  width: auto;
}

.inline-btn-group {
  display: flex;

  input {
    flex: 1 1 auto;
    min-width: 0;
  }
  .btn {
    flex: 0 0 auto;
    margin-left: 2px;
    padding-left: 0.8em;
    padding-right: 0.8em;
    overflow-wrap: normal;
    word-break: normal;
    border-radius: 0 @btn-border-radius @btn-border-radius 0;
  }
}



// -----------------------------------------------------------------------------------------------------------------------------------------
// STATE-RELATED STYLING

.form-error {
  display: block;
  font-size: 0.85rem;
  margin: 0.25em 0 0;
  font-style: italic;
  color: @input-error;

  .dark-bg & {
    color: #fff;
  }
}
.error {
  input,
  textarea,
  .select-wrapper {
    border-color: @input-error;
  }

  label,
  .label {
    color: @input-error;
  }
}
[disabled] {
  cursor: not-allowed;

  input&,
  textarea&,
  select& {
    background-color: @grey1;
  }
}



// -----------------------------------------------------------------------------------------------------------------------------------------
// MEDIA QUERIES

@media screen and (min-width: 35rem) {
  .btn-align {
    display: block;
  }
  .form-group--horiz-align {
    margin-left: 31%;
    margin-left: calc(30% + 1em);
  }

  .form-group--horiz,
  .form-group--horiz-double,
  .form-group--horiz-even,
  .form-group--horiz-radio {
    display: flex;

    label,
    .label {
      display: inline-block;
      vertical-align: top;
      margin-right: 1em;
      padding-top: 0.4em;
      text-align: right;
    }
    .input-group {
      display: inline-block;
      vertical-align: top;
      flex: 1 0 auto;
    }
  }

  .form-group--horiz {
    label,
    .label {
      width: 30%;
      flex: 0 1 30%;
    }
    .input-group {
      width: 65%;
    }
    .input-group--inline-btn {
      display: inline-flex;
    }
  }
  .form-group--horiz-double {
    label,
    .label {
      width: 30%;
      flex: 0 1 30%;
    }
    .input-group {
      width: 31%;
      width: calc(32.5% - 0.5em);
    }
    .input-group + .input-group {
      margin-top: 0;
      margin-left: 1em;
    }
  }
  .form-group--horiz-even {
    label,
    .label {
      width: 48%;
      flex: 0 1 auto;
    }
    .input-group {
      width: 48%;
    }
  }
  .form-group--horiz-radio {
    .label {
      width: 30%;
      flex: 0 1 30%;
    }
    label {
      text-align: left;
      padding-top: 0;
    }
    .input-group {
      padding-top: 0.4em;
      width: 65%;
    }
  }
}
