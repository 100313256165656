.result-box {
  margin-bottom: 1.5rem;
  padding: 0.7em 1em 1.25em;
  border: solid @line-grey;
  border-width: 2px 0 0;
  background-color: @grey1;
  
  > :last-child {
    margin-bottom: 0;
  }
}
