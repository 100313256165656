/// VIS Contact Form ///
.field-div {
  display: table;
  width: 100%;
}

.field-label {
  display: table-cell;
  width: 30%;
  padding-right: 5px;
}

.field-tag {
  width: 60%;
}
/// End VIS Contact Form ///
