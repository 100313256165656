.menu {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  gap: 0.2em;
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu--account {
  font-size: 0.9em;
}

.menu__item {
  flex: 0 1 auto;
  position: relative;
}

.menu__link {
  display: inline-block;
  padding: 0.2em 0.7em;
  color: @theme-light;
  background-color: @theme-dark;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active,
  .active > & {
    background-color: @theme-accent-light;
    text-decoration: none;
  }
}
