// use <span> elements for these, not <i>

.icon {
  display: inline-block;
  vertical-align: baseline;
  width: 1em;
  height: 1em;
  margin: 0 0 -0.1em;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.icon--lower {
  margin-bottom: -0.15em;
}
.icon--lg {
  font-size: 1.25em;
  margin: -0.05em 0 -0.2em;
}
.icon--xl {
  font-size: 1.5em;
  margin: -0.25em 0;
}


.icon-arrow-up {
  background-image: url('@{image-path}icons/icon-arrow-up.svg');
}
.icon-arrow-up--red {
  background-image: url('@{image-path}icons/icon-arrow-up-red.svg');
}
.icon-arrow-up--white {
  background-image: url('@{image-path}icons/icon-arrow-up-white.svg');
}

.icon-arrow-left {
  background-image: url('@{image-path}icons/icon-arrow-left.svg');
}
.icon-arrow-left--red {
  background-image: url('@{image-path}icons/icon-arrow-left-red.svg');
}
.icon-arrow-left--white {
  background-image: url('@{image-path}icons/icon-arrow-left-white.svg');
}

.icon-arrow-right {
  background-image: url('@{image-path}icons/icon-arrow-right.svg');
}
.icon-arrow-right--red {
  background-image: url('@{image-path}icons/icon-arrow-right-red.svg');
}
.icon-arrow-right--white {
  background-image: url('@{image-path}icons/icon-arrow-right-white.svg');
}

.info-tooltip {
  cursor: help;
  opacity: 0.7;
}