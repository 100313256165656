figure {
  display: table;
  margin-left: 0;
  margin-right: 0;
}
figcaption {
  display: table-caption;
  caption-side: bottom;
  font-size: 0.85em;
  margin-top: 0.25em;
  padding-bottom: 0.5em;
  color: hsl(11,3%,40%);
  border-bottom: 1px solid @line-grey;
}
