.breadcrumbs {
  display: block;
  flex: 0 0 auto;
  // margin-bottom: 2rem;

  padding-top: 0.8em;
  padding-bottom: 0.5em;
  background-image: linear-gradient(to bottom, @theme-light, @grey1);
  border-bottom: 1px solid @line-grey;
}
.breadcrumb__list {
  font-size: 0.85em;
  margin: 0 auto;
  list-style: none;
}
.breadcrumb {
  position: relative;
  display: inline-block;
  margin: 0 0.5em 0.4em 0;
  
  &:not(:last-of-type)::after {
    position: absolute;
    display: block;
    content: '';
    width: 0.4em;
    height: 0.4em;
    top: 0.56em;
    right: -1.2em;
    border: solid @grey4;
    border-width: 2px 2px 0 0;
    transform: rotate(45deg);
  }
  
  &:not(:first-of-type) {
    margin-left: 1.25em;
  }
  
}
.breadcrumb__icon {
  fill: @theme-accent;
}

