@import 'css/global/variables';

.back-to-top-button {
  border-radius: 50%;
  height: 45px;
  width: 45px;
  padding: 8px;
  cursor: pointer;
  position: fixed;
  z-index: 10;
  right: 40px;
  bottom: 40px;
  background-color: @theme-accent;
  box-shadow: @box-shadow[basic];
}
.back-to-top-button:hover {
  animation: pulse 1s infinite;
  transition: .3s;
  box-shadow: @box-shadow[basic];
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
  }
}
