.secondary-nav {
  font-size: 0.85em;
  padding: 0.3rem 1em 0;
  background-color: @theme-accent;
  background-image: linear-gradient(to bottom, @theme-accent-light, @theme-accent);
}
.secondary-nav__list {
  .l-container();
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style: none;
}
.secondary-nav__item {
  flex: 0 0 auto;
  width: auto;

  background-color: hsla(0, 0%, 100%, 0.3);

  & + & {
    margin-left: 1em;
  }
}
.secondary-nav__link {
  display: block;
  padding: 0.25rem 0.7rem 0.4rem;
  color: @theme-light;
  background-color: @theme-accent;
  text-decoration: none;
  // box-shadow: none;

  &:hover,
  &:focus,
  &:active,
  &.is-active {
    color: @theme-accent;
    background-color: @theme-light;
    text-decoration: none;
    // box-shadow: none;
  }

}
