.site-header {
  position: relative;
  overflow: hidden;
  flex: 0 0 auto;
  display: block;
  width: 100%;
  padding: 1em 0 0;

  border-bottom: 3px solid @theme-accent-light;
  background-color: @theme-dark;
  color: @theme-light;

  background-image:
    linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)),
    url('@{image-path}graphthing-lines.svg')
    ;
  background-repeat: no-repeat, no-repeat;
  background-size: cover, 37em;
  background-position: center, -12.7em -10.8em;
  background-attachment: fixed, fixed;

  a {
    color: inherit;
    text-decoration: none;
  }

  .logo {
    height: 50px;
    margin-right: 15px;
  }

  nav {
    display: grid;
    grid-template-areas:
      'logo'
      'account'
      'primary';
  }

  .logo-wrapper {
    grid-area: logo;
    margin-bottom: 1rem;
  }
  .menu--primary {
    grid-area: primary;
  }
  .menu--account {
    grid-area: account;
    margin-top: 1em;
  }
}

.nodegraph {
  position: absolute;
  z-index: -1;
  width: 32em;
  transform: translate(-10.5em, -9em) rotate(190deg);
  opacity: 0.2;

  .ng0,
  .ng1,
  .ng2 {
    fill: none;
    stroke: white;
    stroke-width: 4;
    stroke-miterlimit: 10;
  }
  .ng1,
  .ng2 {
    stroke-width: 3;
  }
  .ng2 {
    stroke-width: 2;
  }
}


@media screen and (min-width: 32em) {
  .site-header {
    nav {
      align-items: center;
      grid-template-areas:
        'logo account'
        'primary primary';
    }
    .menu--account {
      margin-top: 0;
      justify-content: flex-end;
    }
  }
}

@media screen and (min-width: 50em) {
  .site-header {
    .logo {
      height: 2em;
      margin-right: 1em;
    }
  }
}
@media screen and (min-width: 78em) {
  .site-header {
    background-position: center, calc((50vw - 35rem) - 12.7em) -10.8em;
  }
}
