.site-index__heading {
  .h3();
  margin-bottom: 1.5rem;
  font-weight: normal;
}

.site-index {
  list-style: none;
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
}

.site-index__row {
  display: flex;
  margin: 0;
  padding: 0;

  > * {
    flex: 0 1 50%;
    margin: 0;
    padding: 0.5rem 1rem;
    border: solid @line-grey;
    border-width: 1px 0;
  }

  & + & > * {
    border-width: 0 0 1px;
  }
}
.site-index__name {
  padding-left: 0;
}
.site-index__icon {
  flex: 1 3 auto;
  display: flex;
  width: 4.5rem;

  img {
    display: block;
    margin: auto;
    width: 2.5rem;
  }
}
.site-index__info {
  background-color: @grey1;
}
