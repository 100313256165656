img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

[alt] {
  vertical-align: baseline;
}

.green {
    text-color: green;
}
